import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import ReCAPTCHA from "react-google-recaptcha"
import { navigate } from "gatsby"
import styled from "@emotion/styled"

import Layout from "../components/layouts"
import SEO from "../components/seo"
import InputField from "../components/ui/Input"
import { SimpleButton } from "../components/ui/Button"
import Medusa from "../services/api"
import { formatMoneyAmount } from "../utils/prices"
import { useTranslationContext } from "../context/TranslationContext"

const SITE_KEY = process.env.GATSBY_GOOGLE_SITE_KEY

const Container = styled.div`
  margin: 70px 16px;

  h1,
  p {
    font-size: 18px;
    font-weight: 300;
    line-height: 1.4;
  }

  .input-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 400px;
    margin: 0 auto;

    input,
    p {
      font-size: 18px;
    }

    > div {
      width: 100%;
    }

    .view-balance-input {
      height: 40px;
      padding: 0;

      input {
        border: none;
        border-bottom: ${(props) => props.theme.borders.black};
        padding: 0px;
        border-radius: 0px;
        padding-bottom: 5px;
        height: 100%;
      }
    }

    .view-balance-button {
      margin-top: 60px;
      height: 40px;
      padding: 0px;
      text-align: center;
    }

    .continue-shopping {
      margin-top: 30px;
      height: 40px;
      padding: 0px;
      text-align: center;
    }

    .recaptcha {
      margin-top: 30px;
    }

    .error {
      color: ${(props) => props.theme.colors.danger};
      margin-top: 30px;
      margin-bottom: 0px;
      opacity: 0.5;
      width: 100%;
    }

    .available {
      margin-top: 30px;
      margin-bottom: 0px;
      opacity: 0.5;
      width: 100%;
    }
  }

  ${(props) => props.theme.breakpointsLegacy.desktop} {
    max-width: 1000px;
    margin: 100px auto;

    h1,
    p {
      font-size: 24px;
    }

    .input-container {
      margin: 30px auto;
    }
  }
`
const canonicals = {
  ["en-US"]: "/gift-card-balance",
  ["de-DE"]: "/de/geschenkkarten-guthaben",
}

const GiftCardBalance = () => {
  const { setTranslationContext } = useTranslationContext()

  useEffect(() => {
    setTranslationContext({
      locale: "en-US",
      canonicals: canonicals,
    })
  }, [setTranslationContext])

  const [giftCardCode, setGiftCardCode] = useState("")
  const [state, setState] = useState({
    region: null,
    balance: null,
    status: null,
    reCaptcha: null,
  })

  const { t } = useTranslation()
  const handleGiftCard = async () => {
    if (!state.balance || state.status === 404) {
      Medusa.giftCards
        .retreive(giftCardCode)
        .then(({ data }) => {
          data.gift_card &&
            setState({
              ...state,
              region: data.gift_card.region,
              balance: data.gift_card.balance,
              status: 200,
            })
        })
        .catch(() => setState({ ...state, balance: null, status: 404 }))
    } else {
      navigate("/")
    }
  }

  return (
    <Layout>
      <SEO title={"Gift Card Balance"} />
      <Container>
        <h1>{t("gift_card_balance")}</h1>
        <p>{t("gift_card_balance_description")}</p>

        <div className="input-container">
          <InputField
            maxWidth={"100%"}
            value={giftCardCode.toUpperCase()}
            placeholder={`${t("gift_card_number")}:`}
            onChange={(e) => setGiftCardCode(e.target.value)}
            className="view-balance-input"
          />

          {state.balance > 0 && (
            <p className="available">
              {t("amount_available")}:{" "}
              {formatMoneyAmount(
                {
                  currencyCode: state.region?.currency_code,
                  amount: state.balance,
                },
                2,
                state.region?.tax_rate
              )}
            </p>
          )}
          {state.balance === 0 && (
            <p className="available">{t("empty_giftcard")}</p>
          )}

          {state.status === 404 && <p className="error">{t("no_giftcard")}</p>}

          {!state.reCaptcha && SITE_KEY && (
            <div className="recaptcha">
              <ReCAPTCHA
                sitekey={SITE_KEY}
                onChange={() => {
                  setState({ ...state, reCaptcha: true })
                }}
              />
            </div>
          )}

          {state.reCaptcha && (
            <SimpleButton
              full={true}
              large={true}
              onClick={handleGiftCard}
              className="view-balance-button"
            >
              {state.balance ? t("continue_shopping") : t("view_balance")}
            </SimpleButton>
          )}
        </div>
      </Container>
    </Layout>
  )
}

export default GiftCardBalance
